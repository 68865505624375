module.exports = {
  A: {
    A: {
      "2": "K D E gC",
      "132": "F A B"
    },
    B: {
      "1": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "1028": "C L M G N O P"
    },
    C: {
      "1": "5 6 7 8 9 uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC",
      "260": "J LB K D E F A B C L M G kC lC",
      "1028": "0 1 2 3 4 N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC"
    },
    D: {
      "1": "5 6 7 8 9 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "548": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB",
      "1028": "PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB"
    },
    E: {
      "1": "FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "mC OC",
      "548": "J LB K D E F A B C L M G nC oC pC qC PC CC DC rC sC tC QC RC EC uC"
    },
    F: {
      "1": "pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x DC",
      "2": "F",
      "548": "B C yC zC 0C 1C CC eC 2C",
      "1028": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB"
    },
    G: {
      "1": "FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "16": "OC",
      "548": "E 3C fC 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND"
    },
    H: {
      "132": "QD"
    },
    I: {
      "1": "I",
      "16": "RD SD",
      "548": "IC J TD UD fC",
      "1028": "VD WD"
    },
    J: {
      "548": "D A"
    },
    K: {
      "1": "H DC",
      "548": "A B C CC eC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "132": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z PC cD dD eD fD gD FC GC HC hD",
      "1028": "J XD YD ZD aD bD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "kD lD"
    }
  },
  B: 4,
  C: "Media Queries: resolution feature",
  D: true
};