@if (data$ | async) {
	<label class="yuno-form">
		<span class="select-none"> Select the Marker </span>
		<select class="truncate" [(ngModel)]="markerValue" (ngModelChange)="onSelectMarker($event)">
			<option [value]="undefined" hidden>Select marker...</option>
			<option [value]="null">Zoom to Self</option>
			@for (select of service.markerValues; track select; let i = $index) {
				<option [value]="select">
					{{ returnDisplay(i, select) }}
				</option>
			}
		</select>
	</label>
	<yuno-admin-annotation type="info" class="mt-4">
		<div class="flex flex-col">
			<yuno-forms-span class="font-semibold">*Zoom to Self / Place Markers</yuno-forms-span>
			<yuno-forms-span>
				This Feature requires a <strong>Marker</strong> or <strong>Point layer</strong> to
				work.
			</yuno-forms-span>
			<yuno-forms-span class="mt-2">
				Use <strong>Zoom to Self</strong> for letting <strong>Place Markers</strong> zoom to
				their own location.
			</yuno-forms-span>
		</div>
	</yuno-admin-annotation>
}
@if (form) {
	<form [formGroup]="form" class="grid grid-cols-1 gap-4 mt-4">
		<ng-container>
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				@if (form.get('options.coordinates')) {
					<div formArrayName="coordinates" class="grid grid-cols-2 gap-4">
						<yuno-forms-number [formControlName]="0" label="Lng (X)" />
						<yuno-forms-number [formControlName]="1" label="Lat (Y)" />
					</div>
				}
				<yuno-forms-number formControlName="zoom" label="Zoom" />

				<yuno-forms-radio formControlName="mode" [radioMap]="zoomToRadioMap" />
				@if ($others()) {
					<yuno-forms-number
						formControlName="bearing"
						label="bearing"
						[min]="-360"
						[max]="360"
						[step]="1" />
					<yuno-forms-number
						formControlName="pitch"
						label="pitch"
						[min]="0"
						[max]="60"
						[step]="1" />
				}
			</div>
		</ng-container>
	</form>
}
