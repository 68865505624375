import { AsyncPipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	DestroyRef,
	OnInit,
	inject,
	signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, tap } from 'rxjs';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsModule } from '@yuno/angular/forms';
import { Marker, ZoomToEvent, zoomToOptionsModeRadioMap } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to-marker',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, YunoFormsModule, AnnotationComponent, AsyncPipe],
	templateUrl: './zoom-to-marker.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToMarkerComponent extends EventsDefaultFormItemComponent implements OnInit {
	private destroyRef = inject(DestroyRef);

	zoomToRadioMap = zoomToOptionsModeRadioMap;
	$others = signal(false);

	markerValue: string | null = null;
	markers: Partial<Marker>[] = [];

	data$ = this.service.markers$.pipe(
		tap(data => {
			if (data) {
				this.service.markerDisplay = data.map(object => object.properties?.id) as string[];
				this.service.markerValues = data.map(object => object._id) as string[];
				this.markers = data;
			}
		})
	);

	ngOnInit(): void {
		this.handleFormValueChanges();
		this.initializeMarkers();
		this.initializeOptions();
	}

	private handleFormValueChanges(): void {
		this.form.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				distinctUntilChanged(
					(prev, curr) =>
						(prev.options as ZoomToEvent['options'])?.mode ===
						(curr.options as ZoomToEvent['options'])?.mode
				)
			)
			.subscribe(value => {
				const options = value.options as ZoomToEvent['options'];
				this.setOptions(options);
			});
	}

	private initializeMarkers(): void {
		const markers = this.service.$markers();
		if (!markers || markers.length < 1) {
			this.service.getMarkers();
		}
	}

	private initializeOptions(): void {
		const options = this.form.get('options') as FormGroup;
		const optionsValue = options?.value;

		if (optionsValue?.id) {
			this.markerValue = optionsValue.id;
		}

		if (!optionsValue?.coordinates) {
			this.markerValue = null;
		}

		this.onSelectMarker(optionsValue?.id || 'null');
		this.setOptions(optionsValue);
	}

	setOptions(options: ZoomToEvent['options']): void {
		const optionsForm = this.form.get('options');
		if (!optionsForm) return;

		if (options.mode === 'custom') {
			optionsForm.get('bearing')?.enable();
			optionsForm.get('pitch')?.enable();
			this.$others.set(true);
			return;
		}

		optionsForm.get('bearing')?.disable();
		optionsForm.get('pitch')?.disable();
		this.$others.set(false);
	}

	onSelectMarker(markerId: string): void {
		const options = this.form.get('options') as FormGroup;

		if (markerId === 'null') {
			this.markerValue = null;
			this.service.removeControls(options, ['id', 'coordinates']);
			return;
		}

		if (!options.controls['coordinates']) {
			this.service.generateControls(options, { id: undefined, coordinates: [0, 0] }, [
				{ name: 'coordinates', length: 2 }
			]);
		}

		const marker = this.markers.find(marker => marker._id === markerId);
		if (marker?.geometry?.coordinates) {
			options.get('coordinates')?.patchValue(marker.geometry.coordinates);
			options.get('id')?.patchValue(markerId);
		}
	}

	returnDisplay(index: number, fallback: string | undefined): string | undefined {
		return this.service.markerDisplay?.[index] ?? fallback;
	}
}
