import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AsyncPipe, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	PLATFORM_ID,
	Renderer2,
	SimpleChanges,
	ViewChild,
	inject
} from '@angular/core';
import { distinctUntilChanged, lastValueFrom, tap } from 'rxjs';

import { ControlZoomHandlerComponent } from '@yuno/angular/control-zoom-handler';
import { LazyInject } from '@yuno/angular/services';
import { PanoramaDataDTO } from '@yuno/api/interface';
import { inIframe } from '@yuno/libs/shared/helpers';

import { KrpanoEventsService } from '../../services/krpano-events.service';
import { PanoramaService } from '../../services/panorama.service';
import { ResizeService } from '../../services/resize.service';
import { WindowRefService } from '../../services/window-ref.service';
import { ThreejsLoaderService } from '../../threejs/threejs.loader.service';
import {
	FovType,
	KrpanoDevice,
	KrpanoInstance,
	KrpanoInterface,
	LimitViewType,
	View,
	ViewTypes,
	ViewTypesArr
} from '../../types';
import { CalibrationToolComponent } from '../calibration-tool/calibration-tool.component';
import { IdleBadgeAnimationTime, IdleBadgeComponent } from '../idle-badge/idle-badge.component';

const panoramaIdleTime = 60 * 1000;

@Component({
	standalone: true,
	imports: [ControlZoomHandlerComponent, AsyncPipe],
	selector: 'yuno-krpano',
	template: `
		<control-zoom-handler [wheelEvent]="onWheelEvent" [type]="'pano'" />

		<div #container></div>
		@if (panoramaData?.alt; as alt) {
			<div class="sr-only" [attr.aria-label]="alt">{{ alt }}</div>
		}
		@if (offsetSub | async) {
			<ng-container></ng-container>
		}
	`,
	styleUrls: ['./panorama.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanoramaComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	private el = inject(ElementRef);
	private cdr = inject(ChangeDetectorRef);
	private zone = inject(NgZone);
	private renderer = inject(Renderer2);
	private plaform = inject(PLATFORM_ID);

	private lazyInjector = inject(LazyInject);

	private resize = inject(ResizeService);
	private winRef = inject(WindowRefService);
	private panorama = inject(PanoramaService);
	private events = inject(KrpanoEventsService);

	@ViewChild('container', { static: true, read: ElementRef }) private container: ElementRef;

	private overlay = inject(Overlay);
	private overlayRef?: OverlayRef;

	private threeLoader?: ThreejsLoaderService;

	onWheelEvent: WheelEvent;
	offsetSub = this.resize.offset$.pipe(
		distinctUntilChanged(
			(prev, curr) =>
				!(
					prev?.height !== curr?.height ||
					prev?.locX !== curr?.locX ||
					prev?.locY !== curr?.locY
				)
		),
		tap(data => {
			this.panorama.offset = data;
		})
	);
	private overlayRefCalibration?: OverlayRef;

	private idleTimer?: number;
	private idle = false;
	private zoomWheelTimer: number;

	// Output of the KRpano Global HTML5 container
	@Output() krpano = new EventEmitter<KrpanoInstance>();

	@Input() xml: string;
	@Input() controlZoom = false;

	@Input() set color(col: string) {
		this.panorama.color = col;
	}

	get color(): string {
		return this.panorama.color;
	}

	@Input() element: HTMLElement;
	@Input() enableLogs = false;

	@Input() set calibrate(calibrate: boolean) {
		calibrate ? this.openCalibrationTool() : this.closeCalibrationTool();
	}

	private _keepView = false;
	@Input() set keepView(bool: boolean) {
		this._keepView = bool;
		this.panorama.keepView = this._keepView;
	}

	// Optional values
	// VR
	@Input() set vrModeEnabled(bool: boolean) {
		this.panorama.vrModeEnabled = bool || false;
	}

	@Input() set vrModeActivated(bool: boolean) {
		this.panorama.vrModeActivated = bool || false;
	}

	// THREEJS
	@Input() set threejsActivated(bool: boolean) {
		this.panorama.threejsActivated = bool || false;
	}

	get threejsActivated(): boolean {
		return this.panorama.threejsActivated;
	}

	@Input() set threejsRange(range: number) {
		this.panorama.range = range || 2000;
	}

	get threejsRange(): number {
		return this.panorama.range;
	}

	// x,y || x,y,z
	@Input() set coordinates(coord: [number, number] | [number, number, number] | undefined) {
		if (!coord) {
			return;
		}
		this.panorama.coordinates = [coord[0], coord[1]];

		if (coord.length === 3) {
			this.panorama.height = coord[2];
		}
	}

	// Project EPSG
	@Input() set epsg(epsg: string | undefined) {
		if (!epsg) {
			return;
		}
		this.panorama.epsg = epsg;
	}

	// Panorama Height
	@Input() set height(height: number | undefined) {
		if (!height) {
			return;
		}
		this.panorama.height = height;
	}

	// Panorama Data
	@Input() set panoramaData(pano: PanoramaDataDTO) {
		this.panorama.panorama = pano;
	}

	get panoramaData(): PanoramaDataDTO | undefined {
		return this.panorama.panorama;
	}

	// Zoom Input
	@Input() set zoom(zoom: number) {
		this.panorama.zoomOnPanorama(zoom);
	}

	// KRPANO VIEW INPUTS
	// documentation https://krpano.com/docu/xml/#view
	@Input() hlookat: number;
	@Input() vlookat: number;
	@Input() camroll: number;

	@Input() fovtype: FovType;
	@Input() fov: number;
	@Input() fovmin: number;
	@Input() fovmax: number;

	@Input() maxpixelzoom: number;
	@Input() mfovratio: number;

	@Input() distortion: number;
	@Input() distortionfovlink: number;

	@Input() stereographic: boolean;
	@Input() pannini: number;
	@Input() architectural: number;
	@Input() architecturalonlymiddle: boolean;

	@Input() limitview: LimitViewType;
	@Input() hlookatmin: number;
	@Input() hlookatmax: number;
	@Input() vlookatmin: number;
	@Input() vlookatmax: number;

	@Input() rx: number;
	@Input() ry: number;

	@Input() tx: number;
	@Input() ty: number;
	@Input() tz: number;

	@Input() ox: number;
	@Input() oy: number;
	@Input() oz: number;

	@Input() disableDefaultIdleEvent: boolean;

	// KRPANO Events
	// documentation https://krpano.com/docu/xml/#events
	@Output() onenterfullscreen = new EventEmitter<void>();
	@Output() onexitfullscreen = new EventEmitter<void>();
	@Output() onxmlcomplete = new EventEmitter<void>();
	@Output() onpreviewcomplete = new EventEmitter<void>();
	@Output() onloadcomplete = new EventEmitter<void>();
	@Output() onblendcomplete = new EventEmitter<void>();
	@Output() onnewpano = new EventEmitter<void>();
	@Output() onremovepano = new EventEmitter<void>();
	@Output() onnewscene = new EventEmitter<void>();
	@Output() onxmlerror = new EventEmitter<void>();
	@Output() onloaderror = new EventEmitter<void>();
	@Output() onkeydown = new EventEmitter<void>();
	@Output() onkeyup = new EventEmitter<void>();
	@Output() onclick = new EventEmitter<void>();
	@Output() onsingleclick = new EventEmitter<void>();
	@Output() ondoubleclick = new EventEmitter<void>();
	@Output() onmousedown = new EventEmitter<void>();
	@Output() onmouseup = new EventEmitter<void>();
	@Output() onmousewheel = new EventEmitter<void>();
	@Output() oncontextmenu = new EventEmitter<void>();
	@Output() onidle = new EventEmitter<View>();
	@Output() onviewchange = new EventEmitter<View>();
	@Output() onviewchanged = new EventEmitter<View>();
	@Output() onresize = new EventEmitter<void>();
	@Output() onframebufferresize = new EventEmitter<void>();
	@Output() onautorotatestart = new EventEmitter<void>();
	@Output() onautorotatestop = new EventEmitter<void>();
	@Output() onautorotateoneround = new EventEmitter<void>();
	@Output() onautorotatechange = new EventEmitter<void>();
	@Output() oniphonefullscreen = new EventEmitter<void>();
	@Output() gyro_onavailable = new EventEmitter<void>();
	@Output() gyro_onunavailable = new EventEmitter<void>();
	@Output() gyro_onenable = new EventEmitter<void>();
	@Output() gyro_ondisable = new EventEmitter<void>();
	@Output() webvr_onavailable = new EventEmitter<void>();
	@Output() webvr_onunavailable = new EventEmitter<void>();
	@Output() webvr_onunknowndevice = new EventEmitter<void>();
	@Output() webvr_onentervr = new EventEmitter<void>();
	@Output() webvr_onexitvr = new EventEmitter<void>();

	@Output() wheelEvent: EventEmitter<WheelEvent> = new EventEmitter<WheelEvent>();

	@HostListener('window:resize', ['$event']) onResize() {
		this.threeLoader?.resizeThree();
	}

	@HostListener('wheel', ['$event']) windowWheelEvent(event: WheelEvent) {
		if (!inIframe() || !this.controlZoom) {
			return;
		}

		event.preventDefault();
		this.wheelEvent.emit(event);
		this.onWheelEvent = event;

		this.zoomWheelTimer && clearTimeout(this.zoomWheelTimer);
		if (event.ctrlKey) {
			this.panorama.krpanoInstance.call(`set(fov_moveforce, ${event.deltaY < 0 ? -1 : 1})`);

			this.zoomWheelTimer = window.setTimeout(() => {
				this.panorama.krpanoInstance.call('set(fov_moveforce, 0)');
			}, 75);
		}
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.plaform)) {
			this.zone.runOutsideAngular(async () => {
				// Create reference to this component for external javascript functions inside Krpano
				this.winRef.nativeWindow['KrpanoComponentRef'] = {
					component: this,
					zone: this.zone
				};

				this.onResize();

				// Load the krpanoscript
				await this.panorama.loadKrpanoScript(this.renderer);
				this.panorama.setup({
					panoEvents: this,
					panoOptions: {
						xml: this.xml || '',
						element: this.element || this.container.nativeElement,
						enableLogs: this.enableLogs
					}
				});

				// Wait for creation, then emit the KrpanoInstance
				await lastValueFrom(this.panorama.panoramaCreated$);
				this.krpano.emit(this.panorama.krpanoInstance);

				if (!this.disableDefaultIdleEvent) {
					this.defaultIdleEvent();

					setTimeout(() => {
						this.setIdle(false);
					}, 2500);
				}

				if (inIframe()) {
					this.controlZoom &&
						this.panorama.krpanoInstance.set('control.disablewheel', true);
				}
			});
		}
	}

	ngAfterViewInit() {
		this.panorama.el = this.el;
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		// wait for the Creation of the KrpanoInstance before we can do any of the changes
		await lastValueFrom(this.panorama.panoramaCreated$);

		for (const propName in changes) {
			const change = changes[propName];

			// Checks if the Change is one of the ViewType Changes
			if (ViewTypesArr.includes(propName)) {
				const name = propName as ViewTypes;
				await this.panorama.setViewSettings(name, change.currentValue);

				return;
			}

			// When XML changes update the Panorama
			if (!changes[propName].firstChange && propName === 'xml') {
				await this.panorama.updatePanorama(
					changes[propName].currentValue,
					!!changes['panoramaData']
				);
				// activate the ThreeJS
				// resizing method
				// after a panorama has been loaded
				// and resized itself
				this.onResize();
			}
		}
	}

	// Sets up the Idle Event timer
	// When triggerd, after 1 minute
	// We show the 360 badge
	callIdleTimer(view: View): void {
		/* 1minut timer */
		if (!this.idleTimer) {
			this.idleTimer = window.setTimeout(() => {
				const { fov } = view;

				if (fov > 1) {
					this.setIdle(true);
				}

				clearTimeout(this.idleTimer);
				this.idleTimer = undefined;
			}, panoramaIdleTime);
		}
	}

	async registerThreeJS(
		krpano?: KrpanoInterface,
		device?: KrpanoDevice,
		plugin?: unknown
	): Promise<void> {
		if (this.threeLoader) {
			await this.threeLoader.updateScene();
			return;
		}

		// Lazyload the ThreejsLoaderService
		this.threeLoader = await this.lazyInjector.get<ThreejsLoaderService>(() =>
			import('./../../threejs/threejs.loader.service').then(m => m.ThreejsLoaderService)
		);

		await this.threeLoader.register(krpano, device, plugin);
	}

	setIdle(bool: boolean): void {
		this.idle = bool;

		if (!bool) {
			this.idleTimer && clearTimeout(this.idleTimer);
			this.idleTimer = undefined;

			if (this.overlayRef) {
				// When using Dispose the removing animation
				// wont play
				this.overlayRef.detach();

				// After the Animation we can dispose it
				setTimeout(() => {
					this.overlayRef?.dispose();
					this.overlayRef = undefined;
				}, IdleBadgeAnimationTime);
			}

			this.cdr.markForCheck();
			return;
		}

		if (this.overlayRef) {
			return;
		}

		this.overlayRef = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			width: '100%',
			height: '100%',
			panelClass: 'no-pointer-events'
		});

		const consentPortal = new ComponentPortal(IdleBadgeComponent);
		this.overlayRef.attach(consentPortal);

		this.cdr.markForCheck();
	}

	// Set Default Event
	defaultIdleEvent(): void {
		if (!this.panorama.krpanoInstance) {
			return;
		}

		this.panorama.krpanoInstance.events.onidle = () => {
			this.onidle.emit(this.events.getView());
			this.callIdleTimer({
				vlookat: this.panorama.krpanoInstance?.view?.vlookat || 0,
				hlookat: this.panorama.krpanoInstance?.view?.hlookat || 0,
				fov: this.panorama.krpanoInstance?.view?.fov || 0
			});
		};

		this.panorama.krpanoInstance.events.onmousedown = () => {
			this.setIdle(false);
		};

		// basic filtering of flat panos
		this.panorama.krpanoInstance?.view?.fov > 1 && this.setIdle(true);
	}

	// setup the Calibration Tool
	async openCalibrationTool(): Promise<void> {
		// Wait for creation, then emit the KrpanoInstance
		await lastValueFrom(this.panorama.panoramaCreated$);

		this.overlayRefCalibration = this.overlay.create({
			positionStrategy: this.overlay.position().global(),
			width: '100%',
			height: '100%',
			panelClass: 'no-pointer-events'
		});

		const consentPortal = new ComponentPortal(CalibrationToolComponent);
		this.overlayRefCalibration.attach(consentPortal);

		this.cdr.markForCheck();
	}

	closeCalibrationTool(): void {
		this.overlayRefCalibration?.detach();
		this.overlayRefCalibration?.dispose();
		this.cdr.markForCheck();
	}

	ngOnDestroy(): void {
		// Then dispose of it when the animation is compelte
		this.idleTimer && clearTimeout(this.idleTimer);
		this.idleTimer = undefined;

		// Removes the 360 badge
		if (this.overlayRef) {
			// When the Idle Badge is still active, remove it
			this.overlayRef.detach();

			setTimeout(() => {
				this.overlayRef?.dispose();
			}, IdleBadgeAnimationTime);
		}

		// Removes the calibration tool
		if (this.overlayRefCalibration) {
			this.closeCalibrationTool();
		}

		this.threeLoader?.removeThree();
		this.panorama.destroy();
	}
}
