<router-outlet />

@if (data$ | async; as data) {
	<section class="grid grid-cols-1">
		<yuno-admin-table
			[selectable]="false"
			[draggable]="false"
			[filterable]="true"
			[sortable]="false"
			[pagination]="true"
			[pageOptions]="{
				pageSize: 100,
				pageSizeOptions: [5, 10, 25, 100],
				hidePageSize: true
			}"
			[data]="getData(data.list)"
			[buttons]="tableButtons"
			[columns]="[
				{
					key: 'id',
					label: 'Participation'
				}
			]"
			(clicked)="onView($event)">
			@if (xkpMember && minimalAppRole(userRoles.ADMIN)) {
				<button yuno-admin-button (click)="onCreate()" [add]="true">New Category</button>
			}
		</yuno-admin-table>
	</section>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-view-button (click)="onView(row)"></button>
	<button yuno-admin-duplicate-button (click)="onDuplicate(row)"></button>
	<button yuno-admin-edit-button (click)="onEdit(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
