module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "1": "5 6 7 8 9 T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "33": "S",
      "164": "Q H R",
      "388": "C L M G N O P"
    },
    C: {
      "1": "5 6 7 8 9 H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "164": "VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q",
      "676": "0 1 2 3 4 hC IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB kC lC"
    },
    D: {
      "1": "5 6 7 8 9 T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "33": "S",
      "164": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R"
    },
    E: {
      "1": "RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "164": "J LB K D E F A B C L M G mC OC nC oC pC qC PC CC DC rC sC tC QC"
    },
    F: {
      "1": "5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "33": "2B 3B 4B",
      "164": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B"
    },
    G: {
      "1": "RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "164": "E OC 3C fC 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "164": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "164": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A",
      "388": "B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z fD gD FC GC HC hD",
      "164": "J XD YD ZD aD bD PC cD dD eD"
    },
    Q: {
      "164": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "164": "kD"
    }
  },
  B: 5,
  C: "CSS Appearance",
  D: true
};