@if (form) {
	<form [formGroup]="form">
		<div formGroupName="options" class="grid grid-cols-1 gap-4">
			<div formArrayName="coordinates" class="grid grid-cols-2 gap-4">
				<yuno-forms-number [formControlName]="0" label="Lng (X)" />
				<yuno-forms-number [formControlName]="1" label="Lat (Y)" />
			</div>
			<yuno-forms-number formControlName="zoom" label="zoom" />
			<yuno-forms-number formControlName="minZoom" label="minZoom" />

			<yuno-forms-radio formControlName="mode" [radioMap]="zoomToRadioMap" />

			@if ($others()) {
				<yuno-forms-number
					formControlName="bearing"
					label="bearing"
					[min]="-360"
					[max]="360"
					[step]="1" />
				<yuno-forms-number
					formControlName="pitch"
					label="pitch"
					[min]="0"
					[max]="60"
					[step]="1" />
			}
		</div>
	</form>
}
