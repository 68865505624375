import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';

import { Marker } from '@yuno/api/interface';

import { markersActions } from './markers.actions';
import { markersFeature } from './markers.state';

@Injectable()
export class MarkersFacade {
	private readonly store = inject(Store);

	loaded$ = this.store.pipe(select(markersFeature.selectLoaded));
	markers$ = this.store.pipe(select(markersFeature.selectMarkers));
	selectedMarkers$ = this.store.pipe(select(markersFeature.selectSelected));
	selectedLoaded$ = this.store.pipe(select(markersFeature.selectSelectedLoaded));
	status$ = this.store.pipe(select(markersFeature.selectStatus));
	saveStatus$ = this.store.pipe(select(markersFeature.selectSaveStatus));
	mapStyle$ = this.store.pipe(select(markersFeature.selectMapStyle));
	mapStyleBounds$ = this.store.pipe(select(markersFeature.selectMapStyleBounds));

	$markers = toSignal(this.markers$);

	get() {
		this.store.dispatch(markersActions.load());
	}

	load() {
		this.store.dispatch(markersActions.reload());
	}

	select(_id: string | null) {
		if (!_id) {
			return;
		}
		this.store.dispatch(markersActions.select({ _id }));
	}

	removeEmptyMarker(): void {
		this.store.dispatch(markersActions.removeEmptyMarker());
	}

	updateSelect(data: Partial<Marker>) {
		this.store.dispatch(markersActions.updateSelect({ data }));
	}

	clearSelect() {
		this.store.dispatch(markersActions.clearSelect());
	}

	save() {
		this.store.dispatch(markersActions.save());
	}

	duplicate(_id: string) {
		this.store.dispatch(markersActions.duplicate({ _id }));
	}

	delete(_id: string) {
		this.store.dispatch(markersActions.delete({ _id }));
	}

	loadMapStyle(style: string): void {
		this.store.dispatch(markersActions.loadMapStyle({ style }));
	}
}
