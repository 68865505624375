import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	OnInit,
	inject,
	signal
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';

import { AnnotationComponent } from '@yuno/admin/ui';
import { YunoFormsCodeEditorComponent, YunoFormsModule } from '@yuno/angular/forms';
import { ZoomToEvent, zoomToOptionsModeRadioMap } from '@yuno/api/interface';

import { EventsDefaultFormItemComponent } from '../_default/default-form-item.component';

@Component({
	selector: 'yuno-admin-zoom-to-polygon',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		YunoFormsModule,
		YunoFormsCodeEditorComponent,
		AnnotationComponent
	],
	templateUrl: './zoom-to-polygon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ZoomToPolygonComponent
	extends EventsDefaultFormItemComponent
	implements OnInit, AfterViewInit
{
	private readonly cdr = inject(ChangeDetectorRef);
	private destroyRef = inject(DestroyRef);
	polygonValue: string;
	polygonSavedValue: string;
	invalidValue = false;

	zoomToRadioMap = zoomToOptionsModeRadioMap;

	$others = signal(false);

	ngOnInit(): void {
		this.setOptions(this.form.value.options as ZoomToEvent['options']);
		this.form.valueChanges
			.pipe(
				takeUntilDestroyed(this.destroyRef),
				// check if modes change, else we keep in a loop for toggling the disable/enabled values
				distinctUntilChanged((prev, curr) => {
					const prevOptions = prev.options as ZoomToEvent['options'];
					const currOptions = curr.options as ZoomToEvent['options'];

					return prevOptions?.mode === currOptions?.mode;
				})
			)
			.subscribe(val => {
				const options = val.options as ZoomToEvent['options'];
				this.setOptions(options);
			});
	}

	setOptions(options: ZoomToEvent['options']): void {
		const optionsForm = this.form.get('options');
		if (!optionsForm) {
			return;
		}

		if (options.mode === 'map') {
			optionsForm.get('bearing')?.disable();
			optionsForm.get('pitch')?.disable();
			this.$others.set(false);
		}

		if (options.mode === 'custom') {
			optionsForm.get('bearing')?.enable();
			optionsForm.get('pitch')?.enable();
			this.$others.set(true);
		}
	}

	ngAfterViewInit(): void {
		this.polygonValue = this.polygonSavedValue =
			JSON.stringify(this.form?.controls.options.get('polygon')?.value, null, 4) || '[]';

		this.cdr.detectChanges();
	}

	polyChanges(event: string): void {
		try {
			const value: [number, number][] = JSON.parse(event);

			this.invalidValue = false;
			const poly = this.form.controls.options.get('polygon');
			const polyControl = this.form.controls.options.controls.polygon;

			if (!poly || !polyControl) {
				return;
			}

			polyControl.clear();

			for (let val of value) {
				if (!Array.isArray(val)) {
					val = [0, 0];
				}

				const array = new FormArray<FormControl<number>>([
					new FormControl<number>((val[0] as number) || 0, {
						nonNullable: true
					}),
					new FormControl<number>((val[1] as number) || 0, {
						nonNullable: true
					})
				]);

				polyControl.push(array);
			}

			this.cdr.markForCheck();
			this.polygonSavedValue = JSON.stringify(poly.value, null, 4);
		} catch {
			this.invalidValue = true;
			this.cdr.markForCheck();
		}
	}
}
