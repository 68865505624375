module.exports = {
  A: {
    A: {
      "1": "F A B",
      "2": "K D E gC"
    },
    B: {
      "1": "5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "1": "0 1 2 3 4 5 6 7 8 9 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC",
      "33": "kC lC"
    },
    D: {
      "1": "0 1 2 3 4 5 6 7 8 9 A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "33": "J LB K D E F"
    },
    E: {
      "1": "K D E F A B C L M G nC oC pC qC PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "33": "LB",
      "164": "J mC OC"
    },
    F: {
      "1": "0 1 2 3 4 B C G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x 0C 1C CC eC 2C DC",
      "2": "F yC zC"
    },
    G: {
      "1": "E 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "33": "3C fC",
      "164": "OC"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "J I UD fC VD WD",
      "164": "IC RD SD TD"
    },
    J: {
      "1": "A",
      "33": "D"
    },
    K: {
      "1": "B C H CC eC DC",
      "2": "A"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 J y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "kD lD"
    }
  },
  B: 4,
  C: "CSS3 Box-shadow",
  D: true
};