module.exports = {
  A: {
    A: {
      "2": "K D E gC",
      "8": "F",
      "292": "A B"
    },
    B: {
      "1": "5 6 7 8 9 N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "292": "C L M G"
    },
    C: {
      "1": "5 6 7 8 9 oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC J LB K D E F A B C L M G N O P kC lC",
      "8": "0 1 2 3 4 MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB",
      "584": "aB bB cB dB eB fB gB hB iB jB kB lB",
      "1025": "mB nB"
    },
    D: {
      "1": "5 6 7 8 9 sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "2": "0 1 2 J LB K D E F A B C L M G N O P MB y z",
      "8": "3 4 NB OB",
      "200": "PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB",
      "1025": "rB"
    },
    E: {
      "1": "B C L M G PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB mC OC nC",
      "8": "K D E F A oC pC qC"
    },
    F: {
      "1": "eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 4 F B C G N O P MB y z NB yC zC 0C 1C CC eC 2C DC",
      "200": "OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB"
    },
    G: {
      "1": "BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C fC 4C",
      "8": "E 5C 6C 7C 8C 9C AD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "2": "IC J RD SD TD UD",
      "8": "fC VD WD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "292": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z YD ZD aD bD PC cD dD eD fD gD FC GC HC hD",
      "2": "XD",
      "8": "J"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "kD lD"
    }
  },
  B: 4,
  C: "CSS Grid Layout (level 1)",
  D: true
};