module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "1": "5 6 7 8 9 x AB BB CB DB EB FB GB HB IB JB KB I",
      "2": "C L M G N O P",
      "164": "Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2049": "w"
    },
    C: {
      "1": "5 6 7 8 9 w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "0 1 2 3 4 hC IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U kC lC",
      "66": "V W",
      "2305": "Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2820": "X"
    },
    D: {
      "1": "5 6 7 8 9 x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "2": "J LB K D E F A B C L M G N O P MB y",
      "164": "0 1 2 3 4 z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v",
      "2049": "w"
    },
    E: {
      "1": "GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB mC OC nC",
      "132": "A B C L PC CC DC rC",
      "164": "K D E F oC pC qC",
      "1540": "M G sC tC QC RC EC uC FC SC TC UC VC WC vC"
    },
    F: {
      "1": "j k l m n o p q r s t u v w x",
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "164": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h",
      "2049": "i"
    },
    G: {
      "1": "GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C fC 4C",
      "132": "AD BD CD DD ED FD GD HD ID JD",
      "164": "E 5C 6C 7C 8C 9C",
      "1540": "KD LD MD QC RC EC ND FC SC TC UC VC WC OD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "2": "IC J RD SD TD UD fC",
      "164": "VD WD"
    },
    J: {
      "2": "D",
      "164": "A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "164": "EC"
    },
    P: {
      "1": "1 2 3 4",
      "164": "0 J y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "164": "iD"
    },
    R: {
      "164": "jD"
    },
    S: {
      "2": "kD lD"
    }
  },
  B: 5,
  C: "CSS image-set",
  D: true
};