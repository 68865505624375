import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { take } from 'rxjs';

import { GeoPhotoCategoryFacade, GeoPhotoFacade } from '@yuno/admin/features/geophotos';
import { LayoutFacade } from '@yuno/admin/features/layout';
import {
    SidemenuContainerComponent,
    YunoAdminButtonComponent,
    YunoAdminNavbarButtonsComponent,
    YunoEditContainerModule
} from '@yuno/admin/ui';
import { AppDataComponent } from '@yuno/admin/utils';

import { GeoPhotoMapViewerComponent } from './components/map-viewer/map-viewer.component';
import { GeoPhotoMapViewerService } from './components/map-viewer/map-viewer.service';

@Component({
	selector: 'yuno-admin-geophotos-viewer',
	standalone: true,
	imports: [
		RouterModule,
		YunoAdminNavbarButtonsComponent,
		YunoEditContainerModule,
		YunoAdminButtonComponent,
		GeoPhotoMapViewerComponent,
		SidemenuContainerComponent
	],
	providers: [],
	templateUrl: './view.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoPhotoViewerComponent extends AppDataComponent implements OnInit {
	private readonly categoryFacade = inject(GeoPhotoCategoryFacade);
	private readonly photosFacade = inject(GeoPhotoFacade);
	private readonly layoutFacade = inject(LayoutFacade);

	private readonly mapService = inject(GeoPhotoMapViewerService);

	ngOnInit(): void {
		this.route.queryParamMap.pipe(take(1)).subscribe(data => {
			const map = data.get('map')?.split(',');
			if (!map) {
				return;
			}

			this.mapService.basedOnRoute = true;
			this.mapService.zoom = Number(map[2]);
			this.mapService.center = [Number(map[1]), Number(map[0])];
			this.mapService.bearing = Number(map[3]);
			this.mapService.pitch = Number(map[4]);
		});

		/* 	Detects current url and select dataset from url	*/
		this.route.paramMap.pipe(take(1)).subscribe(data => {
			const id = data.get('id');
			if (!id) {
				return;
			}
			this.categoryFacade.select(id);
		});
	}

	openEditor(url: string[]): void {
		// An array of route segments to navigate to.
		let navigateTo = ['./'];

		// Get the first child route segment, if it exists.
		const child = this.route.firstChild?.snapshot.url[0].path;
		// Add the child route segment to the navigation array, if it exists.
		if (child) {
			navigateTo.push(child);
		}
		// Add the 'edit' route segment to the navigation array.
		navigateTo = [...navigateTo, ...url];

		// Clear the selected item from the facade.
		this.photosFacade.clearSelected();

		// Navigate to the specified route segments relative to the current route.
		this.router.navigate(navigateTo, { relativeTo: this.route });
	}

	openSideOptions(bool: boolean) {
		this.layoutFacade.toggleSideoptions(bool);
	}
}
