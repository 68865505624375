module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "2": "C L M G N O P",
      "33": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "2": "0 1 2 3 4 5 6 7 8 9 hC IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC kC lC"
    },
    D: {
      "2": "J LB K D E F A B C L M G N",
      "33": "0 1 2 3 4 5 6 7 8 9 O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC"
    },
    E: {
      "1": "A B C L M G PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB mC OC",
      "33": "K D E F nC oC pC qC"
    },
    F: {
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "33": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C fC",
      "33": "E 4C 5C 6C 7C 8C 9C"
    },
    H: {
      "2": "QD"
    },
    I: {
      "2": "IC J RD SD TD UD fC",
      "33": "I VD WD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C CC eC DC",
      "33": "H"
    },
    L: {
      "33": "I"
    },
    M: {
      "2": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "33": "EC"
    },
    P: {
      "33": "0 1 2 3 4 J y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "33": "iD"
    },
    R: {
      "33": "jD"
    },
    S: {
      "2": "kD lD"
    }
  },
  B: 4,
  C: "CSS Cross-Fade Function",
  D: true
};