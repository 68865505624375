<yuno-sidemenu-container>
	<ng-container buttons>
		@if (minimalAppRole(userRoles.ADMIN)) {
			<yuno-admin-navbar-buttons-container>
				<button yuno-admin-button color="secondary" (click)="openEditor(['edit'])">
					Edit
				</button>
			</yuno-admin-navbar-buttons-container>
		}
	</ng-container>
	<ng-container mapContainer>
		<yuno-admin-geophotos-map-viewer class="absolute left-0 top-0 z-0 h-full w-full" />
	</ng-container>
</yuno-sidemenu-container>
