<router-outlet />
@if (data$ | async; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="false"
		[pagination]="true"
		[pageOptions]="{
			pageSize: 100,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="data.buttons"
		[buttons]="tableButtons"
		[columns]="[
			{ key: 'id', label: 'map-buttons' },
			{ key: 'updatedAt', type: 'date', label: 'last modified', width: 10 }
		]"
		(clicked)="onSelect($event)">
		<button yuno-admin-add-button (click)="onCreate()">New Map UI Button</button>
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-edit-button (click)="onSelect(row)"></button>
	<button yuno-admin-delete-admin-button (click)="onDelete(row)"></button>
</ng-template>
