module.exports = {
  A: {
    A: {
      "1": "B",
      "2": "K D E F gC",
      "164": "A"
    },
    B: {
      "1": "5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "1": "5 6 7 8 9 JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC J LB kC lC",
      "8": "0 1 2 3 4 K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB",
      "328": "bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB"
    },
    D: {
      "1": "5 6 7 8 9 pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "2": "J LB K D E F A B C L M G N O P MB y z",
      "8": "0 1 2 3 4 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB",
      "584": "mB nB oB"
    },
    E: {
      "1": "L M G rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB K mC OC nC",
      "8": "D E F A B C oC pC qC PC CC",
      "1096": "DC"
    },
    F: {
      "1": "cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "8": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB",
      "584": "ZB aB bB"
    },
    G: {
      "1": "HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "8": "E OC 3C fC 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD",
      "6148": "GD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "8": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "8": "D A"
    },
    K: {
      "1": "H",
      "2": "A",
      "8": "B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "B",
      "36": "A"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z YD ZD aD bD PC cD dD eD fD gD FC GC HC hD",
      "2": "XD",
      "8": "J"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "328": "kD"
    }
  },
  B: 2,
  C: "Pointer events",
  D: true
};