module.exports = {
  A: {
    A: {
      "1": "B",
      "2": "K D E F A gC"
    },
    B: {
      "1": "5 6 7 8 9 M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "129": "C L"
    },
    C: {
      "1": "5 6 7 8 9 kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC",
      "260": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB",
      "804": "J LB K D E F A B C L M kC lC"
    },
    D: {
      "1": "5 6 7 8 9 qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "260": "lB mB nB oB pB",
      "388": "QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB",
      "1412": "0 1 2 3 4 G N O P MB y z NB OB PB",
      "1956": "J LB K D E F A B C L M"
    },
    E: {
      "1": "RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "129": "A B C L M G qC PC CC DC rC sC tC QC",
      "1412": "K D E F oC pC",
      "1956": "J LB mC OC nC"
    },
    F: {
      "1": "dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F yC zC",
      "260": "YB ZB aB bB cB",
      "388": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB",
      "1796": "0C 1C",
      "1828": "B C CC eC 2C DC"
    },
    G: {
      "1": "RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "129": "9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC",
      "1412": "E 5C 6C 7C 8C",
      "1956": "OC 3C fC 4C"
    },
    H: {
      "1828": "QD"
    },
    I: {
      "1": "I",
      "388": "VD WD",
      "1956": "IC J RD SD TD UD fC"
    },
    J: {
      "1412": "A",
      "1924": "D"
    },
    K: {
      "1": "H",
      "2": "A",
      "1828": "B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z ZD aD bD PC cD dD eD fD gD FC GC HC hD",
      "260": "XD YD",
      "388": "J"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "260": "kD"
    }
  },
  B: 4,
  C: "CSS3 Border images",
  D: true
};