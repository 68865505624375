import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { StatusPrivate as Status, StatusPrivate } from '@yuno/admin/utils';
import { App, AppUser, Client, Config } from '@yuno/api/interface';

import { appActions } from './app.actions';

export interface AppState {
	app: Partial<App> | undefined;
	config: Partial<Config> | undefined;
	client: Partial<Client> | undefined;
	users: Partial<AppUser>[] | undefined;
	appUrl: string | undefined;
	appId: string | undefined;
	status: StatusPrivate;
}

export const initialState: AppState = {
	app: undefined,
	config: undefined,
	client: undefined,
	users: undefined,
	appUrl: undefined,
	appId: undefined,
	status: Status.PENDING
};

const reducer = createReducer(
	initialState,
	on(appActions.app, state => ({
		...state,
		status: Status.VALIDATING
	})),
	on(appActions.appPending, state => ({
		...state,
		status: Status.PENDING
	})),
	on(appActions.appSuccess, (state, action) => ({
		...state,
		client: action.client,
		app: action.app,
		config: action.config,
		appUrl: action.url,
		appId: action.app._id,
		status: Status.SUCCESS
	})),
	on(appActions.appFailure, () => ({
		...initialState,
		status: Status.FAILED
	})),
	on(appActions.appRedirect, () => ({
		...initialState
	})),
	on(appActions.appClearState, () => ({
		...initialState,
		client: undefined,
		app: undefined,
		config: undefined,
		users: undefined,
		appUrl: undefined,
		appId: undefined,
		status: Status.PENDING
	})),
	on(appActions.updateApp, (state, { data }) => ({
		...state,
		app: {
			...state.app,
			...(data as App)
		}
	})),

	on(appActions.updateSettings, (state, { data }) => ({
		...state,
		config: {
			...state.config,
			...(data as Config)
		}
	})),
	on(appActions.loadAppUsersSuccess, (state, { data }) => ({
		...state,
		users: [...data]
	})),
	on(appActions.loadAppUsersFailure, state => ({
		...state,
		users: []
	}))
);

export const appFeature = createFeature({
	name: 'app',
	reducer,
	extraSelectors: ({ selectConfig, selectClient }) => ({
		selectLanguage: createSelector(selectConfig, config => config?.language || 'nl'),
		selectLanguages: createSelector(selectConfig, config => config?.languages),
		selectClientId: createSelector(selectClient, client => client?._id)
	})
});
