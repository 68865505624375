module.exports = {
  A: {
    A: {
      "2": "K D E F gC",
      "33": "A B"
    },
    B: {
      "1": "5 6 7 8 9 o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "33": "C L M G N O P",
      "132": "Q H R S T U V W",
      "260": "X Y Z a b c d e f g h i j k l m n"
    },
    C: {
      "1": "5 6 7 8 9 dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC J LB kC lC",
      "33": "0 1 2 3 4 K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB"
    },
    D: {
      "1": "5 6 7 8 9 X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "2": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB",
      "132": "pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W"
    },
    E: {
      "1": "GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB mC OC",
      "33": "K D E F A B C L M G nC oC pC qC PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC"
    },
    F: {
      "1": "a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 4 F B C G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB yC zC 0C 1C CC eC 2C DC",
      "132": "cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z"
    },
    G: {
      "1": "GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C",
      "33": "E fC 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "2": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z YD ZD aD bD PC cD dD eD fD gD FC GC HC hD",
      "2": "J",
      "132": "XD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "kD lD"
    }
  },
  B: 4,
  C: "CSS Hyphenation",
  D: true
};