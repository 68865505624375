module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "1": "5 6 7 8 9 C L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "1": "2 3 4 5 6 7 8 9 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "33": "0 1 hC IC J LB K D E F A B C L M G N O P MB y z kC lC"
    },
    D: {
      "1": "5 6 7 8 9 XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "33": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB"
    },
    E: {
      "1": "F A B C L M G qC PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "33": "J LB K D E mC OC nC oC pC"
    },
    F: {
      "1": "2 3 4 C NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x 2C DC",
      "2": "F B yC zC 0C 1C CC eC",
      "33": "0 1 G N O P MB y z"
    },
    G: {
      "2": "E OC 3C fC 4C 5C 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "2": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "33": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "2": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "2": "0 1 2 3 4 J y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "2": "kD lD"
    }
  },
  B: 2,
  C: "CSS3 Cursors: zoom-in & zoom-out",
  D: true
};