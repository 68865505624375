import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, of, switchMap, tap } from 'rxjs';

import { GraphQLService } from '@yuno/angular-graphql';
import { MessageService } from '@yuno/angular/notifications';

import { CREATE_APPLICATION, CreateAppQuery } from '../../utils';
import { createAppActions } from './create-app.actions';

export const createApp = createEffect(
	(
		actions$ = inject(Actions),
		message = inject(MessageService),
		graphql = inject(GraphQLService),
		toast = inject(HotToastService)
	) =>
		actions$.pipe(
			ofType(createAppActions.createApp),
			switchMap(data => {
				return graphql
					.mutate<CreateAppQuery>({
						mutation: CREATE_APPLICATION,
						variables: {
							dto: data.dto
						}
					})
					.pipe(
						toast.observe({
							loading: 'Start creating the Application',
							success: 'Created the Application, starting redirection!',
							error: 'Error creating the application'
						}),
						map(mutate => {
							if (
								!mutate.data ||
								!mutate.data.createApplication ||
								!mutate.data.createApplication._id
							) {
								throw new Error('no app data');
							}

							return createAppActions.createAppSuccess({
								id: mutate.data.createApplication._id
							});
						}),
						catchError(error => of(createAppActions.createAppFailure({ error })))
					);
			})
		),
	{ functional: true }
);

export const createAppSuccess = createEffect(
	(actions$ = inject(Actions), route = inject(ActivatedRoute), router = inject(Router)) =>
		actions$.pipe(
			ofType(createAppActions.createAppSuccess),
			delay(4000),
			tap(action => {
				router.navigate(['internal', action.id, 'dashboard'], {
					relativeTo: route
				});
			})
		),
	{ dispatch: false, functional: true }
);

export const createAppFailure = createEffect(
	(actions$ = inject(Actions), message = inject(MessageService)) =>
		actions$.pipe(
			ofType(createAppActions.createAppFailure),
			tap(action => {
				if (Array.isArray(action.error)) {
					if (action.error?.[0]?.message.includes('duplicate')) {
						return message.sendToast(
							`The url/url's is already in use for this client`,
							'error'
						);
					}

					return message.sendToast(action.error[0].message, 'error');
				}

				return message.sendToast(action.error.message, 'error');
			})
		),
	{ dispatch: false, functional: true }
);
