module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "1": "5 6 7 8 9 L M G N O P Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "2": "C"
    },
    C: {
      "1": "5 6 7 8 9 AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "16": "hC",
      "33": "0 1 2 3 4 IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B kC lC"
    },
    D: {
      "1": "5 6 7 8 9 WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "16": "J LB K D E F A B C L M",
      "132": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB"
    },
    E: {
      "1": "F A B C L M G qC PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "16": "mC OC",
      "132": "J LB K D E nC oC pC"
    },
    F: {
      "1": "1 2 3 4 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "16": "F B yC zC 0C 1C CC",
      "132": "0 C G N O P MB y z eC 2C DC"
    },
    G: {
      "1": "8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "16": "OC 3C",
      "132": "E fC 4C 5C 6C 7C"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "16": "RD SD",
      "132": "IC J TD UD fC VD WD"
    },
    J: {
      "1": "A",
      "132": "D"
    },
    K: {
      "1": "H",
      "2": "A B CC",
      "132": "C eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 J y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "33": "kD"
    }
  },
  B: 1,
  C: "CSS :read-only and :read-write selectors",
  D: true
};