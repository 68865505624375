module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "1": "5 6 7 8 9 Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "2": "C L M G N O P",
      "1028": "W X",
      "1540": "Q H R S T U V"
    },
    C: {
      "1": "5 6 7 8 9 yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC",
      "164": "0 1 2 3 4 IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB kC lC",
      "1540": "bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB"
    },
    D: {
      "1": "5 6 7 8 9 Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "292": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B",
      "1028": "W X",
      "1540": "1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V"
    },
    E: {
      "1": "G tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "292": "J LB K D E F A B C mC OC nC oC pC qC PC CC",
      "1540": "L M DC rC",
      "3076": "sC"
    },
    F: {
      "1": "8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "292": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB",
      "1028": "6B 7B",
      "1540": "qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B"
    },
    G: {
      "1": "MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "292": "E OC 3C fC 4C 5C 6C 7C 8C 9C AD BD CD DD ED",
      "1540": "FD GD HD ID JD KD",
      "3076": "LD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "292": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "292": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z gD FC GC HC hD",
      "292": "J XD YD ZD aD bD",
      "1540": "PC cD dD eD fD"
    },
    Q: {
      "1540": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "1540": "kD"
    }
  },
  B: 5,
  C: "CSS Logical Properties",
  D: true
};