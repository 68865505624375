module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "2": "C L M G N O",
      "260": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "3138": "P"
    },
    C: {
      "1": "5 6 7 8 9 oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC",
      "132": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB kC lC",
      "644": "hB iB jB kB lB mB nB"
    },
    D: {
      "2": "0 1 J LB K D E F A B C L M G N O P MB y z",
      "260": "5 6 7 8 9 pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "292": "2 3 4 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB"
    },
    E: {
      "2": "J LB K mC OC nC oC",
      "260": "M G rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "292": "D E F A B C L pC qC PC CC DC"
    },
    F: {
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "260": "cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "292": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB"
    },
    G: {
      "2": "OC 3C fC 4C 5C",
      "260": "GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "292": "E 6C 7C 8C 9C AD BD CD DD ED FD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "2": "IC J RD SD TD UD fC",
      "260": "I",
      "292": "VD WD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "2": "A B C CC eC DC",
      "260": "H"
    },
    L: {
      "260": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "260": "EC"
    },
    P: {
      "260": "0 1 2 3 4 y z YD ZD aD bD PC cD dD eD fD gD FC GC HC hD",
      "292": "J XD"
    },
    Q: {
      "260": "iD"
    },
    R: {
      "260": "jD"
    },
    S: {
      "1": "lD",
      "644": "kD"
    }
  },
  B: 4,
  C: "CSS clip-path property (for HTML)",
  D: true
};