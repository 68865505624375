<router-outlet />

@if ({ clients: clients$ | async }; as data) {
	<yuno-admin-table
		[selectable]="false"
		[draggable]="false"
		[filterable]="true"
		[sortable]="false"
		[pagination]="true"
		[pageOptions]="{
			pageSize: 100,
			pageSizeOptions: [5, 10, 25, 100],
			hidePageSize: false
		}"
		[data]="getData(data.clients)"
		[buttons]="tableButtons"
		[buttonsWidth]="5"
		[columns]="[{ key: 'id', label: 'clients' }]"
		(clicked)="onSelectClient($event)">
		<div class="flex justify-between">
			<button yuno-admin-button (click)="onCreate()" [add]="true">Add Client</button>
		</div>
	</yuno-admin-table>
}

<ng-template #tableButtons let-row>
	<button yuno-admin-button-table color="primary" (click)="onSelectClient(row)">Edit</button>
</ng-template>
