module.exports = {
  A: {
    D: {
      "2": "J LB K D E F A B C L M G N O P MB y z",
      "33": "0 1 2 3 4 5 6 7 8 9 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC"
    },
    L: {
      "33": "I"
    },
    B: {
      "2": "C L M G N O P",
      "33": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "2": "hC",
      "33": "0 1 2 3 4 5 6 7 8 9 IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC kC lC"
    },
    M: {
      "33": "BC"
    },
    A: {
      "2": "K D E F A B gC"
    },
    F: {
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "33": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    K: {
      "2": "A B C CC eC DC",
      "33": "H"
    },
    E: {
      "2": "J LB K mC OC nC oC xC",
      "33": "D E F A B C L M G pC qC PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC"
    },
    G: {
      "2": "OC 3C fC 4C 5C",
      "33": "E 6C 7C 8C 9C AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC"
    },
    P: {
      "2": "J",
      "33": "0 1 2 3 4 y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    I: {
      "2": "IC J RD SD TD UD fC",
      "33": "I VD WD"
    }
  },
  B: 6,
  C: "width: stretch property",
  D: undefined
};