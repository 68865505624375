@if (service.fences$ | async) {
	@if (form) {
		<form [formGroup]="form">
			<div formGroupName="options" class="grid grid-cols-1 gap-4">
				<yuno-forms-select
					formControlName="id"
					label="Fence to ZoomTo"
					(changes)="onValueChanged($event)"
					[display]="service.fencesDisplay"
					[selectValues]="service.fencesValues"
					[customProperties]="true"
					placeholder="Select a Fence..." />
				@if (customProperty) {
					<yuno-forms-text formControlName="id" />
					<yuno-admin-annotation type="info">
						<yuno-forms-span>
							To use <b>Place Markers</b>, follow this syntax:
							<br />
							<b>&#123;</b><b>&#123;properties.customProperties.--key--&#125;</b>
							<b>&#125;</b>
							<br />
							<br />
							This syntax is used to open the fence stored within the marker, where
							<b>--key--</b> is the form key defined in the <b>Input Field tab</b> in
							the <b>Place Marker Editor</b>.
						</yuno-forms-span>
					</yuno-admin-annotation>
				}
				<yuno-forms-radio formControlName="mode" [radioMap]="zoomToFenceModeRadio" />
				<yuno-forms-number
					formControlName="minZoom"
					label="Minimal zoom"
					placeholder="Select a minimalZoom..." />
				@if ($others()) {
					<yuno-forms-number
						formControlName="bearing"
						label="bearing"
						[min]="-360"
						[max]="360"
						[step]="1" />
					<yuno-forms-number
						formControlName="pitch"
						label="pitch"
						[min]="0"
						[max]="60"
						[step]="1" />
				}
			</div>
		</form>
	}
}
