import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LayoutFacade } from '@yuno/admin/features/layout';

import { SideOptionsMenuComponent } from '../side-options-menu';

@Component({
	selector: 'yuno-sidemenu-container',
	standalone: true,
	imports: [CommonModule, RouterOutlet, SideOptionsMenuComponent],
	template: `
		<div class="pointer-events-none relative flex h-full w-full flex-col overflow-visible">
			<ng-content select="[buttons]" />
			<router-outlet />
			<div class="flex h-full flex-row">
				<div
					class="pointer-events-auto relative h-full w-full overflow-hidden bg-gray-500 p-4 shadow-sm">
					<ng-content select="[mapContainer]" />
				</div>

				@if ($sideoptions(); as sideOptions) {
					<yuno-admin-side-options-menu
						class="pointer-events-auto"
						(openedChange)="openSideOptions($event)"
						[opened]="sideOptions.open" />
				}
			</div>
		</div>
	`,
	styles: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidemenuContainerComponent {
	private readonly layoutFacade = inject(LayoutFacade);
	$sideoptions = this.layoutFacade.$sideoptions;

	openSideOptions(bool: boolean) {
		this.layoutFacade.toggleSideoptions(bool);
	}
}
