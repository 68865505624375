module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "2": "C L M G N O P",
      "1025": "5 6 7 8 9 d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "1537": "Q H R S T U V W X Y Z a b c"
    },
    C: {
      "2": "hC",
      "932": "0 1 2 3 4 IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB kC lC",
      "2308": "5 6 7 8 9 yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC"
    },
    D: {
      "2": "J LB K D E F A B C L M G N O P MB y z",
      "545": "0 1 2 3 4 NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB",
      "1025": "5 6 7 8 9 d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "1537": "gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c"
    },
    E: {
      "1": "FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB K mC OC nC",
      "516": "B C L M G CC DC rC sC tC QC RC EC uC",
      "548": "F A qC PC",
      "676": "D E oC pC"
    },
    F: {
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "513": "UB",
      "545": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB",
      "1025": "e f g h i j k l m n o p q r s t u v w x",
      "1537": "TB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d"
    },
    G: {
      "1": "FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C fC 4C 5C",
      "516": "KD LD MD QC RC EC ND",
      "548": "8C 9C AD BD CD DD ED FD GD HD ID JD",
      "676": "E 6C 7C"
    },
    H: {
      "2": "QD"
    },
    I: {
      "2": "IC J RD SD TD UD fC",
      "545": "VD WD",
      "1025": "I"
    },
    J: {
      "2": "D",
      "545": "A"
    },
    K: {
      "2": "A B C CC eC DC",
      "1025": "H"
    },
    L: {
      "1025": "I"
    },
    M: {
      "2308": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1537": "EC"
    },
    P: {
      "545": "J",
      "1025": "0 1 2 3 4 y z GC HC hD",
      "1537": "XD YD ZD aD bD PC cD dD eD fD gD FC"
    },
    Q: {
      "1537": "iD"
    },
    R: {
      "1537": "jD"
    },
    S: {
      "932": "kD",
      "2308": "lD"
    }
  },
  B: 5,
  C: "Intrinsic & Extrinsic Sizing",
  D: true
};