import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';

import { App, AppUser, Config } from '@yuno/api/interface';

import { appActions } from './app.actions';
import { appFeature } from './app.state';

@Injectable()
export class AppFacade {
	private readonly store = inject(Store);

	$app = toSignal(this.store.pipe(select(appFeature.selectApp)));
	$config = toSignal(this.store.pipe(select(appFeature.selectConfig)));

	appState$ = this.store.pipe(select(appFeature.selectAppState));
	app$ = this.store.pipe(select(appFeature.selectApp));
	language$ = this.store.pipe(select(appFeature.selectLanguage));
	languages$ = this.store.pipe(select(appFeature.selectLanguages));
	config$ = this.store.pipe(select(appFeature.selectConfig));
	client$ = this.store.pipe(select(appFeature.selectClient));
	users$ = this.store.pipe(select(appFeature.selectUsers));
	appUrl$ = this.store.pipe(select(appFeature.selectAppUrl));
	appId$ = this.store.pipe(select(appFeature.selectAppId));
	clientId$ = this.store.pipe(select(appFeature.selectClientId));
	status$ = this.store.pipe(select(appFeature.selectStatus));

	$language = toSignal(this.store.pipe(select(appFeature.selectLanguage)), {
		initialValue: 'nl'
	});

	validateApp(appId: string) {
		this.store.dispatch(appActions.app({ appId }));
	}

	clearApp() {
		this.store.dispatch(appActions.appClear());
	}

	updateApp(data: App) {
		this.store.dispatch(appActions.updateApp({ data }));
	}

	updateSettings(data: Config) {
		this.store.dispatch(appActions.updateSettings({ data }));
	}

	getAppUsers() {
		this.store.dispatch(appActions.loadAppUsers());
	}

	getAppUsersClient() {
		this.store.dispatch(appActions.loadAppUsersClient());
	}

	updateAppUser(user: AppUser) {
		this.store.dispatch(appActions.updateAppUser({ user }));
	}

	addAppUser(user: Partial<AppUser>) {
		this.store.dispatch(appActions.addAppUser({ user }));
	}

	removeAppUser(user: AppUser, client?: boolean) {
		this.store.dispatch(appActions.removeAppUser({ user, client }));
	}

	saveSettings() {
		this.store.dispatch(appActions.saveSettings());
	}
}
