import { Injectable, signal } from '@angular/core';

export type containerType = 'map' | 'data' | 'data-sidemenu';

@Injectable({
	providedIn: 'root'
})
export class MainUiService {
	containerType = signal<containerType>('data');
	containerWidth = 'max-w-screen-xl';

	setContainerType(val: containerType): void {
		this.containerType.set(val);
	}
}
