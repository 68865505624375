import { KeyValuePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

import { YunoFormsLabelRequired } from '../../pipes/label-required/label-required.pipe';
import {
	YunoFormsDefaultComponent,
	YunoFormsDefaultTemplateComponent
} from '../_default/default.component';

@Component({
	selector: 'yuno-forms-radio',
	template: `
		<div>
			<label class="yuno-form">
				<span class="select-none" [innerHtml]="label || '' | labelRequired: required">
				</span>
			</label>

			<ul
				class="flex justify-between"
				[ngClass]="{
					'flex-col': !row,
					'flex-row': row
				}">
				@if (radioValues) {
					@for (radio of radioValues; track radio) {
						<li class="yuno-form-radio">
							<label class="yuno-form row items-center">
								<input
									class="truncate"
									type="radio"
									[formControl]="getFormControl()"
									[value]="radio" />
								<span class="select-none">
									{{ radio }}
								</span>
							</label>
						</li>
					}
				}

				@if (radioMap) {
					@for (radio of radioMap | keyvalue: originalOrder; track radio) {
						<li class="yuno-form-radio">
							<label class="yuno-form row items-center">
								<input
									class="truncate"
									type="radio"
									[formControl]="getFormControl()"
									[value]="radio.key" />
								<span class="select-none">
									{{ radio.value }}
								</span>
							</label>
						</li>
					}
				}
			</ul>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, YunoFormsLabelRequired, KeyValuePipe, NgClass]
})
export class YunoFormsRadioComponent extends YunoFormsDefaultComponent {
	@Input() row = false;
	@Input() radioValues: string[];
	@Input() radioMap: { [key: string]: string };

	public readonly originalOrder = (): number => 0;
}

@Component({
	selector: 'yuno-forms-template-radio',
	template: `
		<div>
			<label class="yuno-form">
				<span class="select-none" [innerHtml]="label || '' | labelRequired: required">
				</span>
			</label>

			<ul>
				@for (radio of radioValues; track radio) {
					<li class="yuno-form-radio">
						<label class="yuno-form">
							<input
								class="truncate"
								type="radio"
								[ngModel]="value"
								(ngModelChange)="valueChange($event)" />
							<span class="select-none">
								{{ radio }}
							</span>
						</label>
					</li>
				}
			</ul>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [FormsModule, YunoFormsLabelRequired],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => YunoFormsRadioTemplateComponent),
			multi: true
		}
	]
})
export class YunoFormsRadioTemplateComponent extends YunoFormsDefaultTemplateComponent {
	@Input() radioValues: string[];
}
