module.exports = {
  A: {
    A: {
      "2": "K gC",
      "2340": "D E F A B"
    },
    B: {
      "2": "C L M G N O P",
      "1025": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "1": "5 6 7 8 9 c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "hC IC kC",
      "513": "xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b",
      "545": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB lC"
    },
    D: {
      "2": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB",
      "1025": "5 6 7 8 9 bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC"
    },
    E: {
      "1": "A B C L M G PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB mC OC nC",
      "164": "K",
      "4644": "D E F oC pC qC"
    },
    F: {
      "2": "0 1 2 3 4 F B G N O P MB y z NB yC zC 0C 1C CC eC",
      "545": "C 2C DC",
      "1025": "OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C fC",
      "4260": "4C 5C",
      "4644": "E 6C 7C 8C 9C"
    },
    H: {
      "2": "QD"
    },
    I: {
      "2": "IC J RD SD TD UD fC VD WD",
      "1025": "I"
    },
    J: {
      "2": "D",
      "4260": "A"
    },
    K: {
      "2": "A B CC eC",
      "545": "C DC",
      "1025": "H"
    },
    L: {
      "1025": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2340": "A B"
    },
    O: {
      "1025": "EC"
    },
    P: {
      "1025": "0 1 2 3 4 J y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "1025": "iD"
    },
    R: {
      "1025": "jD"
    },
    S: {
      "1": "lD",
      "4097": "kD"
    }
  },
  B: 4,
  C: "Crisp edges/pixelated images",
  D: true
};