module.exports = {
  A: {
    A: {
      "1": "A B",
      "2": "K D E F gC"
    },
    B: {
      "1": "C L M G N O P",
      "516": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I"
    },
    C: {
      "132": "mB nB oB pB qB rB sB JC tB KC uB vB wB",
      "164": "0 1 2 3 4 hC IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB kC lC",
      "516": "xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a",
      "1028": "5 6 7 8 9 b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC"
    },
    D: {
      "420": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB",
      "516": "5 6 7 8 9 kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC"
    },
    E: {
      "1": "A B C L M G PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "132": "F qC",
      "164": "D E pC",
      "420": "J LB K mC OC nC oC"
    },
    F: {
      "1": "C CC eC 2C DC",
      "2": "F B yC zC 0C 1C",
      "420": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB",
      "516": "XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x"
    },
    G: {
      "1": "AD BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "132": "8C 9C",
      "164": "E 6C 7C",
      "420": "OC 3C fC 4C 5C"
    },
    H: {
      "1": "QD"
    },
    I: {
      "420": "IC J RD SD TD UD fC VD WD",
      "516": "I"
    },
    J: {
      "420": "D A"
    },
    K: {
      "1": "C CC eC DC",
      "2": "A B",
      "516": "H"
    },
    L: {
      "516": "I"
    },
    M: {
      "1028": "BC"
    },
    N: {
      "1": "A B"
    },
    O: {
      "516": "EC"
    },
    P: {
      "420": "J",
      "516": "0 1 2 3 4 y z XD YD ZD aD bD PC cD dD eD fD gD FC GC HC hD"
    },
    Q: {
      "516": "iD"
    },
    R: {
      "516": "jD"
    },
    S: {
      "164": "kD lD"
    }
  },
  B: 4,
  C: "CSS3 Multiple column layout",
  D: true
};