module.exports = {
  A: {
    A: {
      "2": "K D E F gC",
      "6308": "A",
      "6436": "B"
    },
    B: {
      "1": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "6436": "C L M G N O P"
    },
    C: {
      "1": "5 6 7 8 9 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "2": "0 1 2 3 4 hC IC J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB kC lC",
      "2052": "ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB"
    },
    D: {
      "1": "5 6 7 8 9 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "2": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB",
      "8258": "yB zB 0B"
    },
    E: {
      "1": "B C L M G CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J LB K D E mC OC nC oC pC",
      "3108": "F A qC PC"
    },
    F: {
      "1": "wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "0 1 2 3 4 F B C G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB yC zC 0C 1C CC eC 2C DC",
      "8258": "oB pB qB rB sB tB uB vB"
    },
    G: {
      "1": "CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "2": "E OC 3C fC 4C 5C 6C 7C",
      "3108": "8C 9C AD BD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "2": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "2": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "2": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z PC cD dD eD fD gD FC GC HC hD",
      "2": "J XD YD ZD aD bD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "2052": "kD"
    }
  },
  B: 4,
  C: "CSS Scroll Snap",
  D: true
};