module.exports = {
  A: {
    A: {
      "2": "K D E F A B gC"
    },
    B: {
      "1": "5 6 7 8 9 Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I",
      "36": "C L M G N O P"
    },
    C: {
      "1": "5 6 7 8 9 lB mB nB oB pB qB rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC iC jC",
      "33": "0 1 2 3 4 MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB",
      "130": "hC IC J LB K D E F A B C L M G N O P kC lC"
    },
    D: {
      "1": "5 6 7 8 9 rB sB JC tB KC uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x AB BB CB DB EB FB GB HB IB JB KB I BC MC NC",
      "36": "0 1 2 3 4 J LB K D E F A B C L M G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB hB iB jB kB lB mB nB oB pB qB"
    },
    E: {
      "1": "B C L M G PC CC DC rC sC tC QC RC EC uC FC SC TC UC VC WC vC GC XC YC ZC aC bC wC HC cC dC xC",
      "2": "J mC OC",
      "36": "LB K D E F A nC oC pC qC"
    },
    F: {
      "1": "eB fB gB hB iB jB kB lB mB nB oB pB qB rB sB tB uB vB wB xB yB zB 0B 1B 2B 3B 4B 5B 6B 7B 8B 9B AC Q H R LC S T U V W X Y Z a b c d e f g h i j k l m n o p q r s t u v w x",
      "2": "F B C yC zC 0C 1C CC eC 2C DC",
      "36": "0 1 2 3 4 G N O P MB y z NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB"
    },
    G: {
      "1": "BD CD DD ED FD GD HD ID JD KD LD MD QC RC EC ND FC SC TC UC VC WC OD GC XC YC ZC aC bC PD HC cC dC",
      "2": "OC 3C",
      "36": "E fC 4C 5C 6C 7C 8C 9C AD"
    },
    H: {
      "2": "QD"
    },
    I: {
      "1": "I",
      "36": "IC J RD SD TD UD fC VD WD"
    },
    J: {
      "36": "D A"
    },
    K: {
      "1": "H",
      "2": "A B C CC eC DC"
    },
    L: {
      "1": "I"
    },
    M: {
      "1": "BC"
    },
    N: {
      "36": "A B"
    },
    O: {
      "1": "EC"
    },
    P: {
      "1": "0 1 2 3 4 y z ZD aD bD PC cD dD eD fD gD FC GC HC hD",
      "36": "J XD YD"
    },
    Q: {
      "1": "iD"
    },
    R: {
      "1": "jD"
    },
    S: {
      "1": "lD",
      "33": "kD"
    }
  },
  B: 5,
  C: "::placeholder CSS pseudo-element",
  D: true
};